import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import {
  faDiscord,
  faMediumM,
  faRedditAlien,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { connect } from "react-redux"
import { ChainID, getWeb3, getOnBoard } from "@util/wallet"
import { setWeb3, setOnBoard, setWalletAddress, setConnected } from "@state/actions"
import { menus } from "./menu"
import "./Layout.scss"

const icons = [
  { icon: faDiscord, color: "#8867ff", link: "https://discord.gg/masterbrews" },
  { icon: faTwitter, color: "#62b0e5", link: "https://twitter.com/MasterBrewsNFT" },
  { icon: faRedditAlien, color: "#ff4300", link: "https://reddit.com/r/masterbrews" },
  { icon: faMediumM, color: "#fac045", link: "https://blog.masterbrews.cards" },
]

const Layout = ({
  title = "MasterBrews",
  children,
  contentClassName = "",
  headerClass = "",
  headerStyle = null,
  visibleClass = true,
  visibleFooter = true,
  web3,
  onBoard,
  walletAddress,
  connected,
  setWeb3,
  setOnBoard,
  setConnected,
  setWalletAddress
}) => {
  const [activeIcon, setActiveIcon] = useState("")
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  })

  useEffect(() => {
    setWeb3(window.__web3 || null);
    setOnBoard(window.__onBoard || null);
    setWalletAddress(window.__walletAddress || null);
    setConnected(window.__connected || false);
  }, []);

  useEffect(() => {
    window.__web3 = web3;
  }, [web3]);
  useEffect(() => {
    window.__onBoard = onBoard;
  }, [onBoard]);
  useEffect(() => {
    window.__walletAddress = walletAddress;
  }, [walletAddress]);
  useEffect(() => {
    window.__connected = connected;
  }, [connected]);

  useEffect(() => {
    const addressAvailable = () => {
      if (walletAddress) {
        return;
      }

      if (web3 && web3.currentProvider && web3.currentProvider.selectedAddress &&
        (web3.currentProvider.selectedAddress.length > 0)) {
        setWalletAddress(web3.currentProvider.selectedAddress);
      } else {
        setTimeout(addressAvailable, 100);
      }
    }

    if (web3) {
      addressAvailable();
    }
  }, [web3, walletAddress]);

  useEffect(() => {
    const walletInitialize = async () => {
      const _web3 = await getWeb3()
      const _onBoard = await getOnBoard()
      const _chainId = await _web3.eth.getChainId()
      const _address = await _web3.eth.getAccounts()

      setWeb3(_web3)
      setOnBoard(_onBoard)
      if (_address[0] && _chainId === ChainID)
        setConnected(true)

      setWalletAddress(_address[0])
    }

    if (typeof window !== "undefined") {
      if (window.ethereum) {
        window.ethereum.on('chainChanged', handleNetworkChange);
        window.ethereum.on('disconnect', logout);
        window.ethereum.on('accountsChanged', logout);
      }
    }

    walletInitialize()
  }, [])

  const connectHandler = async () => {
    if (onBoard !== null) {
      if (!(await onBoard.walletSelect())) {
        return;
      }
      setConnected(await onBoard.walletCheck())
    }
  }

  const logout = () => {
    if (onBoard != null) {
      onBoard.walletReset();
    }
    setWalletAddress(null);
    setConnected(false)
  }

  const handleNetworkChange = (networkId) => {
    logout();
    if (networkId !== '0x1') {
      displayNotify("warning", "You've just changed the Ethereum network! The app will not function properly if you selected the wrong network.")
    }
  }

  const sliceAddress = (val) => {
    return val.slice(0, 6) + '...' + val.slice(-4)
  }

  const handleConnectDisconnect = async () => {
    if (!connected) {
      await connectHandler();
    } else {
      logout();
    }
  }

  const displayNotify = (type, content) => {
    setAlertState({
      open: true,
      message: content,
      severity: type,
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="layout">
        <Disclosure
          as="nav"
          className={`header fixed z-50 w-full px-0 ${headerClass} ${visibleClass ? "block" : "hidden"} `}
          style={headerStyle}
        >
          {({ open }) => (
            <>
              <div>
                <div className="px-4 lg:container mx-auto py-4 lg:py-0">
                  <div className="relative flex items-center justify-between">
                    <div className="flex-1 flex items-center justify-between sm:items-stretch min-w-full">
                      <div className="flex-shrink-0 flex items-center">
                        <Link to="/">
                          <img
                            className="block h-8 w-auto"
                            src="/LogoDark.png"
                            alt="Workflow"
                          />
                        </Link>
                      </div>
                      <div className="hidden lg:block sm:ml-6">
                        <div className="flex space-x-4">
                          <div>
                            <ul className="navMenus">
                              {menus.map(dt => (
                                <li className="py-2 px-3" key={dt.name} >
                                  {dt.type == 'internal' ?

                                    <Link
                                      activeClassName="active"
                                      to={dt.path}
                                      partiallyActive={!!dt.partiallyActive}
                                      target={dt.target}
                                    >
                                      {dt.name}
                                    </Link>
                                    :
                                    <a href={dt.path} target={dt.target}>
                                      {dt.name}
                                    </a>
                                  }
                                </li>
                              ))}
                              {
                                connected && walletAddress ?
                                  <li className="py-2 pl-3">
                                    <Link activeClassName="active" to="/mybrews" target="">My BREWs</Link>
                                  </li>
                                  :
                                  ""
                              }
                              <li className="py-2 pl-3">
                                {
                                  connected && walletAddress ?
                                    <div className="py-2 px-4 flex items-center">
                                      <span className="text-xs text-gray-600 mr-1">{sliceAddress(walletAddress)}</span>
                                      <span className="ml-1 rounded-full bg-red-400" onClick={handleConnectDisconnect} style={{ 'display': 'inline-block', 'width': '20px', 'height': '20px', }}></span>
                                    </div>
                                    :
                                    <button
                                      id="connect_button"
                                      className="flex justify-center gap-2 items-center bg-green-400 hover:bg-green-300 text-sm text-black font-bold py-2 px-4 rounded-sm tracking-wide w-full"
                                      onClick={handleConnectDisconnect}
                                    ><span className="text-secondary tracking-wider">Connect</span></button>
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md menuToggleIcon focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="lg:hidden bg-black">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  <div>
                    <ul className="navMenus flex-col">
                      <li className="py-2 px-3 w-full">
                        {
                          connected && walletAddress ?
                            <div className="py-2 px-4 flex justify-center items-center">
                              <span className="text-xs text-gray-600 mr-1">{sliceAddress(walletAddress)}</span>
                              <span className="ml-1 rounded-full bg-red-400" onClick={handleConnectDisconnect} style={{ 'display': 'inline-block', 'width': '20px', 'height': '20px', }}></span>
                            </div>
                            :
                            <button
                              className="flex justify-center gap-2 items-center bg-green-400 hover:bg-green-300 text-sm text-black font-bold py-2 px-4 rounded-sm tracking-wide w-full"
                              onClick={handleConnectDisconnect}
                            ><span className="text-secondary tracking-wider">Connect</span></button>
                        }
                      </li>
                      {
                        connected && walletAddress ?
                          <li className="py-2 px-3" key="mybrews">
                            <Link activeClassName="active" to="/mybrews" target="">My BREWs</Link>
                          </li>
                          :
                          ""
                      }
                      {menus.map(dt => (
                        <li className="py-2 px-3" key={dt.name}>
                          <Link
                            activeClassName="active"
                            to={dt.path}
                            target={dt.target}
                          >
                            {dt.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure
          as="nav"
          className={`top-0 bg-black fixed z-50 w-full px-0 ${headerClass} ${visibleClass ? "hidden" : "block"} `}
          style={headerStyle}
        >
          <>
            <div>
              <div className="container mx-auto py-4">
                <div className="flex items-center justify-center sm:items-stretch min-w-full">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        className="block h-8 w-auto"
                        src="/LogoDark.png"
                        alt="Workflow"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Disclosure>
        <div
          className="contentWrapper flex flex-col bg-secondary relative"
        >
          <main
            className={`content container mx-auto flex-grow flex flex-col ${contentClassName} ${visibleClass ? 'pb-0' : 'pb-0'} `}
          >
            {children}
          </main>
        </div>
        <div className={`footerWrapper min-w-full ${visibleClass && visibleFooter ? "block" : "hidden"} `}>
          <div className="footer container mx-auto w-full flex flex-col md:flex-row items-center justify-center">
            <div>
              <div className="text-center followText">
                Follow Us
              </div>
              <div className="mt-4 flex align-middle justify-center mb-4">
                <ul className="flex justify-between flex-grow gap-4">
                  {icons.map(dt => (
                    <a href={dt.link} target="_blank" rel="noreferrer" key={dt.icon.iconName}>
                      <div
                        onMouseEnter={() => {
                          setActiveIcon(dt.color)
                        }}
                        onMouseLeave={() => {
                          setActiveIcon("")
                        }}
                        className="iconContainer bg-primary-900 rounded-full"
                        style={{
                          boxShadow:
                            activeIcon === dt.color
                              ? `0px 0px 13px 5px ${dt.color}`
                              : "none",
                        }}
                      >
                        <FontAwesomeIcon color={dt.color} icon={dt.icon} />
                      </div>
                    </a>
                  ))}
                </ul>
              </div>
              <div className="copyText text-gray-400 text-center mt-3">
                ©2021 MasterBrews. All rights reserved.
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={alertState.open}
          autoHideDuration={10000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
            className="alert-md"
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  )
}

const stateProps = (state) => ({
  web3: state.web3,
  onBoard: state.onBoard,
  walletAddress: state.walletAddress,
  connected: state.connected
});

const dispatchProps = (dispatch) => ({
  setWeb3: (data) => dispatch(setWeb3(data)),
  setOnBoard: (data) => dispatch(setOnBoard(data)),
  setWalletAddress: (address) => dispatch(setWalletAddress(address)),
  setConnected: (status) => dispatch(setConnected(status))
})

export default connect(stateProps, dispatchProps)(Layout);
