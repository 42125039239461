export const menus = [
  {
    name: "Home",
    path: "/",
    target: "",
    type: 'internal'
  },
  {
    name: "Mint",
    path: "/mint",
    partiallyActive: true,
    target: "",
    type: 'internal'
  },
  {
    name: "NFTs",
    path: "/nfts",
    partiallyActive: true,
    target: "",
    type: 'internal'
  },
  {
    name: "Tokens",
    path: "/tokens",
    partiallyActive: true,
    target: "",
    type: 'internal'
  },
  {
    name: "Artists",
    path: "/artists",
    partiallyActive: true,
    target: "",
    type: 'internal'
  },
  {
    name: "Explorer",
    path: "/gallery",
    partiallyActive: true,
    target: "",
    type: 'internal'
  },
  {
    name: "Team",
    path: "/team",
    target: "",
    type: 'internal'
  },
  {
    name: "Testimonials",
    path: "/testimonials",
    partiallyActive: true,
    target: "",
    type: 'internal'
  },
]